var overlayPanel = {};

$(function () {

    /* Accordions */

    var primaryAccordions = new logicjs.accordions({
        container: ".c-accordions-container.c-accordions-container--primary",
        accordionsClass: ".c-accordion",
        accordionCtrlTarget: ".c-accordion__toggle",
        hiddenClass: "c-accordion--hidden",
        accordionView: ".c-accordion__view",
        loadActiveClass: "c-accordion--open",
        toggleAccordions: true,
        loadingClass: "c-accordions-container--loading"
    });

    var secondaryAccordions = new logicjs.accordions({
        container: ".c-accordions-container.c-accordions-container--secondary",
        accordionsClass: ".c-accordion.c-accordion--secondary",
        accordionCtrlTarget: ".c-accordion__toggle",
        hiddenClass: "c-accordion--hidden",
        accordionView: ".c-accordion__view",
        loadActiveClass: "c-accordion--open",
        toggleAccordions: true,
        loadingClass: "c-accordions-container--loading"
    });

    var generalAccordions = new logicjs.accordions({
        container: ".c-accordions-container.c-accordions-container--general",
        accordionsClass: ".c-accordion.c-accordion--general",
        accordionCtrlTarget: ".c-accordion__toggle",
        hiddenClass: "c-accordion--hidden",
        accordionView: ".c-accordion__view",
        loadActiveClass: "c-accordion--open",
        toggleAccordions: true,
        loadingClass: "c-accordions-container--loading"
    });

    var navDropdownAccordion = new logicjs.accordions({
        container: ".c-navigation-dropdown",
        accordionsClass: ".c-accordion",
        accordionsPath: " > ",
        accordionCtrlTarget: ".c-accordion__toggle",
        hiddenClass: "c-accordion--hidden",
        accordionView: ".c-accordion__view",
        loadActiveClass: "c-accordion--open",
        loadingClass: "c-navigation-dropdown--loading"
    });

    var menuSubNav = new logicjs.accordions({
        container: ".c-list-expander",
        accordionsClass: ".c-list-expander__item",
        accordionCtrlTarget: ".c-list-expander__toggle-btn",
        activeClass: "c-list-expander--active",
        hiddenClass: "c-list-expander--hidden",
        accordionView: ".c-list-expander__view",
        toggleAccordions: true
    });



    /* The 'label' functionality that sits above the input fields */

    var materialInputs = new logicjs.inputsTyped({
        activeClass: "value-added",
        parentTarget: ".c-reactive-form-field--anim",
        selectFocusClass: "select-focused"
    });



    /* Scroll to section from internal link (anchor) */

    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });



    /* Cookie policy prototype */

    var cookiePolicy = new logicjs.cookiePolicy();



    /* Carousel */

    var carousel = {};

    carousel.load = function (carouselElement, carouselWrap, index) {

        if ($(carouselElement).hasClass(carousel.carouselLargeClass)) {
            carousel.loadLarge(carouselElement, carouselWrap, index);
        } else if ($(carouselElement).hasClass(carousel.carouselSmallClass)) {
            carousel.loadSmall(carouselElement, carouselWrap, index);
        } else {
            return;
        }
    };

    carousel.loadLarge = function (carouselElement, carouselWrap, index) {

        $(carouselElement).addClass("c-carousel-container--" + index);

        $(carouselWrap[index]).slick({
            slidesToShow: carousel.opts.slidesToShow,
            slidesToScroll: carousel.opts.slidesToScroll,
            arrows: true,
            dots: false,
            appendArrows: '.c-carousel-container--' + index + ' .slider-nav-container .slider-nav',
            appendDots: '.c-carousel-container--' + index + ' .slider-nav-container .slider-nav',
            variableWidth: false,
            rows: 0,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: carousel.opts.slidesToShowBp1,
                    slidesToScroll: carousel.opts.slidesToScrollBp1
                }
            },
            {
                breakpoint: 645,
                settings: {
                    slidesToShow: carousel.opts.slidesToShowBp2,
                    slidesToScroll: carousel.opts.slidesToScrollBp2
                }
            }
            ]
        });
    };

    carousel.loadSmall = function (carouselElement, carouselWrap, index) {

        $(carouselElement).addClass("c-carousel-container--" + index);

        $(carouselWrap[index]).slick({
            slidesToShow: carousel.opts.slidesToShow,
            slidesToScroll: carousel.opts.slidesToScroll,
            arrows: true,
            dots: false,
            appendArrows: '.c-carousel-container--' + index + ' .slider-nav-container .slider-nav',
            appendDots: '.c-carousel-container--' + index + ' .slider-nav-container .slider-nav',
            variableWidth: false,
            rows: 0,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: carousel.opts.slidesToShowBp1,
                    slidesToScroll: carousel.opts.slidesToScrollBp1
                }
            }]
        });
    };

    carousel.setDefaults = function (carouselElem) {
        if ($(carouselElem).hasClass(carousel.carouselLargeClass)) {
            carousel.carouselLarge($(carouselElem).find("li"));
        }

        if ($(carouselElem).hasClass(carousel.carouselSmallClass)) {
            carousel.carouselSmall($(carouselElem).find("li"));
        }
    };

    carousel.carouselLarge = function (carouselItem) {
        carousel.opts = {};

        carousel.opts.slideCount = carouselItem.length;

        switch (carousel.opts.slideCount) {
            case 2:
                carousel.opts.slidesToShow = 2;
                carousel.opts.slidesToShowBp1 = 2;
                carousel.opts.slidesToShowBp2 = 1;

                carousel.opts.slidesToScroll = 1;
                carousel.opts.slidesToScrollBp1 = 1;
                carousel.opts.slidesToScrollBp2 = 1;
                break;
            case 1:
                carousel.opts.slidesToShow = 1;
                carousel.opts.slidesToShowBp1 = 1;
                carousel.opts.slidesToShowBp2 = 1;

                carousel.opts.slidesToScroll = 1;
                carousel.opts.slidesToScrollBp1 = 1;
                carousel.opts.slidesToScrollBp2 = 1;
                break;
            default:
                carousel.opts.slidesToShow = 3;
                carousel.opts.slidesToShowBp1 = 2;
                carousel.opts.slidesToShowBp2 = 1;

                carousel.opts.slidesToScroll = 1;
                carousel.opts.slidesToScrollBp1 = 1;
                carousel.opts.slidesToScrollBp2 = 1;
                break;
        }
    };

    carousel.carouselSmall = function (carouselItem) {
        carousel.opts = {};

        carousel.opts.slideCount = carouselItem.length;

        switch (carousel.opts.slideCount) {
            case 1:
                carousel.opts.slidesToShow = 1;
                carousel.opts.slidesToShowBp1 = 1;

                carousel.opts.slidesToScroll = 1;
                carousel.opts.slidesToScrollBp1 = 1;
                break;
            default:
                carousel.opts.slidesToShow = 2;
                carousel.opts.slidesToShowBp1 = 1;

                carousel.opts.slidesToScroll = 1;
                carousel.opts.slidesToScrollBp1 = 1;
                break;
        }
    };

    carousel.init = function () {
        carousel.carouselLargeParentClass = "c-carousel-container.c-carousel-container--large .c-carousel";
        carousel.carouselSmallParentClass = "c-carousel-container.c-carousel-container--small .c-carousel";

        carousel.carouselContainerClass = "c-carousel-container";

        carousel.carouselLargeClass = "c-carousel-container--large";
        carousel.carouselSmallClass = "c-carousel-container--small";

        var carouselElem;
        var i;
        var element;

        if ($("." + carousel.carouselLargeParentClass).length > 0 ||
            $("." + carousel.carouselSmallParentClass).length > 0) {

            carouselElem = $("." + carousel.carouselContainerClass);

            if ($("." + carousel.carouselLargeParentClass).length > 0) {

                carousel.carouselLargeParent = $("." + carousel.carouselLargeParentClass);

                for (i = 0; i < carousel.carouselLargeParent.length; i++) {
                    element = $("." + carousel.carouselContainerClass + "." + carousel.carouselLargeClass)[i];

                    carousel.setDefaults(carouselElem[i]);
                    carousel.load(element, carousel.carouselLargeParent, i);
                }
            }


            if ($("." + carousel.carouselSmallParentClass).length > 0) {

                carousel.carouselSmallParent = $("." + carousel.carouselSmallParentClass);

                for (i = 0; i < carousel.carouselSmallParent.length; i++) {
                    element = $("." + carousel.carouselContainerClass + "." + carousel.carouselSmallClass)[i];

                    carousel.setDefaults(carouselElem[i]);
                    carousel.load(element, carousel.carouselSmallParent, i);
                }
            }
        }
    }();



    /* Tabs on the detailed pages */

    var tabs = {};

    tabs.bind = function () {
        for (var i = 0; i < tabs.buttons.length; i++) {
            tabs.checkUrlSearchParam(i);

            $(tabs.buttons[i]).off('click').on('click', function () {
                tabs.deactivate();
                tabs.activate($(this));
                tabs.update($(this).index());
            });
        }

        if (!tabs.reference) {
            tabs.deactivate();
            tabs.activate($(tabs.buttons[0]));
            tabs.update($(tabs.buttons[0]).index());
        }
    };

    tabs.checkUrlSearchParam = function (index) {
        if (window.location.search !== "") {
            if (tabs.buttons[index] === tabs.buttons[0]) {
                tabs.reference = "RPMI";
            } else if (tabs.buttons[index] === tabs.buttons[1]) {
                tabs.reference = "WebSupport";
            } else {
                return;
            }

            tabs.urlCheck($(tabs.buttons[index]));
        }
    };

    tabs.urlCheck = function (tab) {
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };

        var searchParam = getUrlParameter('Tab');
        if (tabs.reference == searchParam) {
            tabs.deactivate();
            tabs.activate(tab);
            tabs.update(tab.index());
        }
    };

    tabs.deactivate = function () {
        for (var i = 0; i < tabs.buttons.length; i++) {
            $(tabs.buttons[i]).removeClass(tabs.active);
        }
    };

    tabs.activate = function (tab) {
        tab.addClass(tabs.active);

        if (tab.parents(".c-accordion").length > 0) {
            if (!tab.parents(".c-accordion").hasClass("c-accordion--hidden")) {
                tab.parents(".c-accordion").addClass("c-accordion--hidden");
                tab.parents(".c-accordion__view").css("max-height", 0);
            }
        }
    };

    tabs.update = function (index) {

        for (var i = 0; i < tabs.viewContainer.length; i++) {

            if (index === i) {
                $(tabs.viewContainer[i]).removeClass(tabs.viewHiddenClass);
            } else {
                $(tabs.viewContainer[i]).addClass(tabs.viewHiddenClass);
            }
        }
    };

    tabs.initialise = function () {
        tabs.tabContainerClass = ".c-tabs";
        tabs.viewAreaClass = ".c-tabs__view";
        tabs.buttonsClass = ".c-tabs__button";
        tabs.active = 'active';
        tabs.viewHiddenClass = 'c-tabs__hidden';

        if ($(tabs.tabContainerClass).length > 0) {

            tabs.tabContainer = $(tabs.tabContainerClass);
            tabs.viewContainer = tabs.tabContainer.find(tabs.viewAreaClass);
            tabs.buttons = tabs.tabContainer.find(tabs.buttonsClass);

            tabs.bind();
        }
    }();



    /* AJAX Overlay */
    /* Initialise the popup overlay window when certain CTAs are clicked */


    overlayPanel.bind = function () {
        overlayPanel.clickOpen();
    };

    overlayPanel.clickOpen = function () {
        for (var i = 0; i < overlayPanel.overlayCTAOpen.length; i++) {
            var _ = this;

            overlayPanel.overlayCTAOpen.off("click").on("click", function (e) {
                e.preventDefault();

                overlayPanel.appendOverlay($(this));
            });
        }
    };

    overlayPanel.clickClose = function () {
        overlayPanel.overlayCTAClose.off("click").on("click", function (e) {
            e.preventDefault();

            overlayPanel.removeOverlay();
        });
    };

    overlayPanel.clickOverlayShadow = function () {
        $("." + overlayPanel.overlayPanelContainerClass).off("click").on("click", function (e) {

            if ($(e.target).hasClass(overlayPanel.overlayPanelVisibleClass)) {
                e.preventDefault();
                overlayPanel.removeOverlay();
            }
        });
    };

    overlayPanel.appendOverlay = function (dataValue) {
        overlayPanel.checkExists();
        overlayPanel.getRequest(dataValue);
        overlayPanel.outerPage.addClass(overlayPanel.outerPageFixedClass);
        overlayPanel.addOverlayShadow();
    };

    overlayPanel.removeOverlay = function () {
        overlayPanel.outerPage.removeClass(overlayPanel.outerPageFixedClass);
        overlayPanel.removeOverlayShadow();

        overlayPanel.bannerImage.css("background-image", "");
        overlayPanel.title.empty();
        overlayPanel.text.empty();

        overlayPanel.overlayPanelInner.addClass(overlayPanel.overlayPanelLoadingClass);
    };

    overlayPanel.addOverlayShadow = function () {
        setTimeout(function () {
            overlayPanel.overlayPanelContainer.addClass(overlayPanel.overlayPanelVisibleClass);
        }, 1);
    };

    overlayPanel.removeOverlayShadow = function () {
        $("." + overlayPanel.overlayPanelContainerClass).removeClass(overlayPanel.overlayPanelVisibleClass);
    };

    overlayPanel.checkExists = function () {
        if (!$("." + overlayPanel.overlayPanelContainerClass).length > 0) {
            overlayPanel.createMarkup();
        }

        overlayPanel.overlayPanelContainer = $("." + overlayPanel.overlayPanelContainerClass);
        overlayPanel.overlayPanelInner = overlayPanel.overlayPanelContainer.find("." + overlayPanel.overlayPanelInnerClass);

        overlayPanel.overlayCTAClose = $("." + overlayPanel.overlayCTACloseClass);
        overlayPanel.bannerImage = overlayPanel.overlayPanelContainer.find("." + overlayPanel.bannerImageClass);
        overlayPanel.title = overlayPanel.overlayPanelContainer.find("." + overlayPanel.titleClass + " h2");
        overlayPanel.text = overlayPanel.overlayPanelContainer.find("." + overlayPanel.textClass).find(".c-content");

        overlayPanel.clickClose();
        overlayPanel.clickOverlayShadow();
    };

    overlayPanel.getRequest = function (button) {

        var data = $(button).attr("data-news-id"),
            pathname = "/end-point/news-articles/get-document.ashx?fdNewsArticleId=" + data,
            targetUrl = pathname;

        $.ajax({
            xhr: function () {
                var xhr = new window.XMLHttpRequest();

                // Upload progress
                xhr.upload.addEventListener("progress", function (evt) {
                    if (evt.lengthComputable) {
                        //Do something with upload progress
                        overlayPanel.overlayPanelInner.removeClass(overlayPanel.overlayPanelLoadingClass);
                    }
                }, false);

                // Download progress
                xhr.addEventListener("progress", function (evt) {
                    if (evt.lengthComputable) {
                        // Do something with download progress
                        overlayPanel.overlayPanelInner.removeClass(overlayPanel.overlayPanelLoadingClass);
                    }
                }, false);

                return xhr;
            },
            type: "GET",
            url: targetUrl,
            contentType: "application/json; charset=utf-8",
            success: function (r) {
                //console.log(r);
                overlayPanel.applyData(r);
            },
            error: function (r) {
                console.error(r);
            }
        });
    };

    overlayPanel.applyData = function (data) {
        var bannerImg = data.ImageUrl,
            title = data.Title,
            text = data.Content;

        overlayPanel.bannerImage.css({
            "background-image": "url(" + bannerImg + ")"
        });
        overlayPanel.title.append(title);
        overlayPanel.text.append(text);
    };

    overlayPanel.createMarkup = function () {
        var html = '<div class="c-overlay-panel"><div class="c-overlay-panel__wrap"><div class="o-gutter"><div class="c-overlay-panel__inner c-overlay-panel__inner--loading"><div class="c-overlay-panel__banner"><div class="c-overlay-panel__banner-image"></div></div><div class="c-overlay-panel__content"><div class="c-overlay-panel__title"><div class="c-content"><h2></h2></div></div><div class="c-overlay-panel__text"><div class="c-content"></div></div><div class="c-overlay-panel__cta"><button class="c-button c-button--overlay-close" type="button">Back</button></div></div></div></div></div></div>';

        $("body").append(html);
    };

    overlayPanel.init = function () {
        overlayPanel.outerPageClass = "o-outer-page";
        overlayPanel.outerPageFixedClass = "o-outer-page--fixed";

        overlayPanel.overlayCTAOpenClass = "c-button--overlay-open";
        overlayPanel.overlayCTACloseClass = "c-button--overlay-close";
        overlayPanel.overlayPanelContainerClass = "c-overlay-panel";
        overlayPanel.overlayPanelVisibleClass = "c-overlay-panel--visible";
        overlayPanel.overlayShadowContainerClass = "o-content";

        overlayPanel.overlayPanelInnerClass = "c-overlay-panel__inner";
        overlayPanel.overlayPanelLoadingClass = "c-overlay-panel__inner--loading";
        overlayPanel.bannerImageClass = "c-overlay-panel__banner-image";
        overlayPanel.dateClass = "c-overlay-panel__date";
        overlayPanel.titleClass = "c-overlay-panel__title";
        overlayPanel.textClass = "c-overlay-panel__text";

        overlayPanel.overlayCTAOpen = $("." + overlayPanel.overlayCTAOpenClass);

        if (overlayPanel.overlayCTAOpen.length > 0) {
            overlayPanel.outerPage = $("." + overlayPanel.outerPageClass);

            overlayPanel.overlayShadowContainer = $("." + overlayPanel.overlayShadowContainerClass);

            overlayPanel.bind();
        }
    }

    overlayPanel.runOnce = function () { overlayPanel.init(); }();


    /* Hero Alert Carousel */
    /* Initialise slick slider in the hero-box region*/

    if ($('.c-hero-box').length > 0) {
        $('.c-hero-box').slick({
            fade: true,
            arrows: false,
            speed: 600,
            autoplay: true,
            autoplaySpeed: 5000,
            cssEase: 'ease-in-out'
        });
    };



    /* Site Navigation */

    var siteNav = {};

    siteNav.bind = function () {
        siteNav.siteNavBtn.off("click").on("click", function () {
            siteNav.checkState();
        });

        siteNav.additionalCloseBtn.off("click").on("click", function () {
            siteNav.checkState();
        });
    };

    siteNav.checkState = function () {
        if (!siteNav.outerPage.hasClass(siteNav.siteNavActiveClass)) {
            siteNav.setActive();
        } else {
            siteNav.removeActive();
        }
    };

    siteNav.setActive = function () {
        siteNav.outerPage.addClass(siteNav.siteNavActiveClass);
    };

    siteNav.removeActive = function () {
        siteNav.outerPage.removeClass(siteNav.siteNavActiveClass);
    };

    siteNav.init = function () {

        siteNav.siteNavBtnClass = "c-site-header__nav-btn";
        siteNav.additionalCloseBtnClass = "c-site-nav-container__close-btn";
        siteNav.outerPageClass = "o-outer-page";
        siteNav.siteNavActiveClass = "nav-shown";

        if ($("." + siteNav.siteNavBtnClass).length > 0) {
            siteNav.siteNavBtn = $("." + siteNav.siteNavBtnClass);
            siteNav.additionalCloseBtn = $("." + siteNav.additionalCloseBtnClass);
            siteNav.outerPage = $("." + siteNav.outerPageClass);

            siteNav.bind();
        }
    }();



    /* Inline Navigation Bar */

    var navBar = {};

    navBar.bind = function () {

        navBar.navLinks.off('click.navlink').on('click.navlink', function (e) {
            navBar.viewArea = navBar.container.find("." + navBar.accordionViewClass).height();

            var obj = navBar.selectId(this);

            if (obj !== "") {
                e.preventDefault();

                if ($("." + navBar.mobNavBarClass).length > 0) {
                    navBar.mobNavAccordion = navBar.container.find("." + navBar.mobNavBarClass);
                    navBar.scrollToSection(this, navBar.mobNavAccordion, navBar.hiddenClass);
                } else if ($("." + navBar.navBarClass).length > 0) {
                    navBar.navAccordion = navBar.container.find("." + navBar.navBarClass);
                    navBar.scrollToSection(this, navBar.navAccordion, navBar.hiddenClass);
                } else {
                    return;
                }

                navBarAccordion.hideAll();
            }
        });

        navBar.listeners();
    };

    navBar.selectId = function (link) {
        var value = link.getAttribute("href"),
            array = value.split('/');

        if (array.length === 1) {
            return array[0];
        } else {
            return array[array.length - 1];
        }
    };

    navBar.scrollToSection = function (link, accordion, hiddenClass) {
        var id = navBar.selectId(link);

        if (navBar.container.hasClass(navBar.fixed)) {

            if (window.matchMedia !== undefined) {
                if (window.matchMedia("(min-width:1024px)").matches) {
                    $('html, body').animate({
                        scrollTop: $(id).offset().top - (navBar.navBarOffsetHeight - 1)
                    }, 500);
                } else {
                    $('html, body').animate({
                        scrollTop: $(id).offset().top - (navBar.navBarOffsetHeight - 1)
                    }, 500);
                }
            }
        } else {
            if (window.matchMedia !== undefined) {
                if (window.matchMedia("(min-width:1024px)").matches) {
                    $('html, body').animate({
                        scrollTop: $(id).offset().top - (navBar.navBarOffsetHeight - 1)
                    }, 500);
                } else {
                    $('html, body').animate({
                        scrollTop: $(id).offset().top - (navBar.navBarOffsetHeight + navBar.viewArea - 1)
                    }, 500);
                }
            }
        }
    };

    navBar.listeners = function () {

        window.addEventListener('resize', function () {
            navBar.viewArea = navBar.container.find("." + navBar.accordionViewClass).height();

            if (!navBar.container.hasClass(navBar.fixed)) {
                navBar.navBarOffsetTop = document.getElementsByClassName(navBar.navBarClass)[0].offsetTop;
            }
        });

        window.addEventListener('scroll', function () {
            navBar.windowScrollTop = $(window).scrollTop();

            if (!navBar.container.hasClass(navBar.fixed)) {
                navBar.navBarOffsetTop = document.getElementsByClassName(navBar.navBarClass)[0].offsetTop;
            }

            if (navBar.windowScrollTop > navBar.navBarOffsetTop) {
                navBar.container.addClass(navBar.fixed);
                navBar.mainSection.style.marginTop = navBar.initialNavBarOffsetHeight + "px";
            } else {
                navBar.container.removeClass(navBar.fixed);
                navBar.mainSection.style.marginTop = "0";
            }
        });
    };

    navBar.init = function () {
        navBar.container = $('.c-navigation-bar');
        navBar.navBarClass = "c-accordion--nav-bar";
        navBar.mobNavBarClass = "c-mob-accordion--nav-bar";
        navBar.accordionViewClass = "c-accordion__view";
        navBar.hiddenClass = "c-accordion--hidden";
        navBar.fixed = "c-navigation-bar--fixed";

        if (navBar.container.length > 0) {
            navBar.navLinks = navBar.container.find('a');

            if (navBar.container.find("." + navBar.navBarClass).length > 0) {
                navBar.initialNavBarOffsetHeight = document.getElementsByClassName(navBar.navBarClass)[0].offsetHeight;
                navBar.navBarOffsetHeight = document.getElementsByClassName(navBar.navBarClass)[0].offsetHeight;
                navBar.navBarOffsetTop = document.getElementsByClassName(navBar.navBarClass)[0].offsetTop;
            } else if (navBar.container.find("." + navBar.mobNavBarClass).length > 0) {
                navBar.initialNavBarOffsetHeight = document.getElementsByClassName(navBar.mobNavBarClass)[0].offsetHeight;
                navBar.navBarOffsetHeight = document.getElementsByClassName(navBar.mobNavBarClass)[0].offsetHeight;
                navBar.navBarOffsetTop = document.getElementsByClassName(navBar.mobNavBarClass)[0].offsetTop;
            } else {
                console.log("Nav containers not found");
            }

            navBar.mainSection = document.getElementsByClassName('o-main-content')[0];
            navBar.mainSectionTop = navBar.mainSection.offsetTop;

            navBar.bind();
        }
    }();



    /* Footable */
    /* Initialise responsive tables */

    // $('.footable').footable({
    //     "showToggle": true,
    //     breakpoints: {
    //         phone: 500,
    //         tablet: 727,
    //         desktop: 820
    //     }
    // });



    /* Back to the top button */
    /* A button that appears in mobile view but disappears on tablet and desktop */

    var backToTop = {};

    backToTop.setup = function () {
        backToTop.createContainer();
        backToTop.createButton();
        backToTop.initElements();
        backToTop.checkDistance();
        backToTop.addEventListener();
        backToTop.bindClick();
    };

    backToTop.createContainer = function () {
        var element = "div",
            opts = {
                class: "c-page-top-btn"
            }
        parent = "body";

        backToTop.createElement(element, opts, parent);
    };

    backToTop.createButton = function () {
        var element = "button",
            opts = {
                class: "c-page-top-btn__button",
                type: "button"
            }
        parent = ".c-page-top-btn";

        backToTop.createElement(element, opts, parent);
    };

    backToTop.initElements = function () {
        backToTop.container = document.querySelector(".c-page-top-btn");
        backToTop.button = backToTop.container.querySelector(".c-page-top-btn__button");
    };

    backToTop.addEventListener = function () {
        window.addEventListener("scroll", backToTop.scrollEvent);
    };

    backToTop.scrollEvent = function () {
        backToTop.checkDistance();
    };

    backToTop.checkDistance = function () {
        if (backToTop.getDistance()) {
            if (backToTop.container.classList.contains("c-page-top-btn--hidden")) {
                backToTop.container.classList.remove("c-page-top-btn--hidden");
            }
        } else {
            if (!backToTop.container.classList.contains("c-page-top-btn--hidden")) {
                backToTop.container.classList.add("c-page-top-btn--hidden");
            }
        };
    };

    backToTop.bindClick = function () {
        $(backToTop.button).off("click").on("click", backToTop.handleClick);
    };

    backToTop.handleClick = function () {
        if (backToTop.getDistance()) {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        };
    };

    backToTop.getDistance = function () {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            //page is scrolled down, show button
            return true;
        } else {
            //page is at the top of less than 100px from the top, hide button
            return false;
        }
    }

    backToTop.createElement = function (element, obj, parent) {
        var element = document.createElement(element),
            appendedElement = document.querySelector(parent);

        for (var prop in obj) {
            element.setAttribute(prop, obj[prop]);
        }

        appendedElement.append(element);
    };

    backToTop.init = function () {
        backToTop.setup();
    }();



    /* Password Revealer */
    /* A toggle state to reveal the password if user chooses to check this */

    var displayPassword = {};

    displayPassword.setup = function () {
        displayPassword.eventListeners();
    };

    displayPassword.eventListeners = function () {
        var id;

        $("#fdShowPassword").change(function () {
            id = "pnlPassword";
            displayPassword.stateListener(this, id);
        });

        $("#fdShowConfirmPassword").change(function () {
            id = "pnlConfirmPassword";
            displayPassword.stateListener(this, id);
        });
    };

    displayPassword.stateListener = function (input, elem) {
        if (input.checked) {
            document.getElementById(elem).getElementsByTagName("input")[0].type = "text";
        } else {
            document.getElementById(elem).getElementsByTagName("input")[0].type = "password";
        }
    };

    displayPassword.init = function () {
        if ($("input[type=password]").length > 0) {
            displayPassword.setup();
        }
    }();



    /* Uploader */
    /* Custom browser upload feature that hides features upon button clicks, and changes the browser default input file type to use a custom build input workaround */

    var uploader = {};

    uploader.bind = function () {
        uploader.initFakeInput();
        uploader.cancelBtn.off("click").on("click", function () {
            uploader.removeFile($(this));
            uploader.hideFileInput($(this));
            uploader.showFileDetails($(this));
        });
        uploader.replaceBtn.off("click").on("click", function () {
            uploader.hideFileDetails($(this));
            uploader.showFileInput($(this));
        });
    };

    uploader.initFakeInput = function () {
        for (var i = 0; i < uploader.container.length; i++) {
            var container = $(uploader.container[i]);
            var imageUpload = container.find(uploader.inputTarget);
            imageUpload.on("change", function () {
                var uploadMsg = $(this).parents("." + uploader.containerClass).find("." + uploader.fileUploadMsgClass),
                    input = this.files[0],
                    text,
                    val = $(this).val();

                if (input) {
                    text = 'Selected File to upload: <strong>' + val.replace(/.*\\(.+)/g, "$1") + "</strong>";
                } else {
                    text = 'Please select a file';
                }

                uploadMsg.html(text);
            });
        }
    };

    uploader.removeFile = function (selected) {
        var container = selected.parents("." + uploader.containerClass);
        var input = container.find(uploader.inputTarget);
        var uploadMsg = container.find("." + uploader.fileUploadMsgClass);
        input.val("");
        uploadMsg.html("No File Selected");
    };

    uploader.hideFileInput = function (selected) {
        var container = selected.parents("." + uploader.containerClass);
        var fileOpts = container.find("." + uploader.fileOptsClass);
        fileOpts.addClass(uploader.fileOptsHiddenClass);
    };

    uploader.showFileInput = function (selected) {
        var container = selected.parents("." + uploader.containerClass);
        var fileOpts = container.find("." + uploader.fileOptsClass);
        fileOpts.removeClass(uploader.fileOptsHiddenClass);
    };

    uploader.hideFileDetails = function (selected) {
        var container = selected.parents("." + uploader.containerClass);
        var fileDetails = container.find("." + uploader.fileDetailsClass);
        fileDetails.addClass(uploader.fileDetailsHiddenClass);
    };

    uploader.showFileDetails = function (selected) {
        var container = selected.parents("." + uploader.containerClass);
        var fileDetails = container.find("." + uploader.fileDetailsClass);
        fileDetails.removeClass(uploader.fileDetailsHiddenClass);
    };

    uploader.init = function () {
        uploader.containerClass = "c-file-upload";
        uploader.cancelBtnClass = "c-file-upload__cancel";
        uploader.replaceBtnClass = "c-file-upload__replace";
        uploader.fileOptsClass = "c-file-upload__file-opts";
        uploader.fileOptsHiddenClass = "c-file-upload__file-opts--hidden";
        uploader.fileDetailsClass = "c-file-upload__file-details";
        uploader.fileDetailsHiddenClass = "c-file-upload__file-details--hidden";
        uploader.fileUploadMsgClass = "c-file-upload__upload-msg";
        uploader.inputTarget = "input[type=file]";
        uploader.container = $("." + uploader.containerClass);
        uploader.fileInput = uploader.container.find(uploader.inputTarget);

        if (uploader.fileInput.length > 0) {
            uploader.cancelBtn = uploader.container.find("." + uploader.cancelBtnClass);
            uploader.replaceBtn = uploader.container.find("." + uploader.replaceBtnClass);
            uploader.bind();
        }
    }();
});



// function colorboxModal() {
//     $(".cbox").colorbox({ iframe: true, scrolling: true, width: "80%", height: "80%" });
// }

// function imageEffects() {
//     $("img[data-src-popout]").click(function () {
//         $.colorbox({ iframe: true, href: "/images/colorbox/overlay.png", width: "80%", height: "80%" });
//     });
//     $("img[data-src-hover]").hover(
//         function () {
//             var src = $(this).attr('data-src-hover');
//             $(this).attr('data-src-hover', $(this).attr('src'));
//             $(this).attr('src', src);
//         }
//     );
// }

document.addEventListener("DOMContentLoaded", function () {

    if (document.getElementById("HomePagePopupDontShowAgain") !== null) {
        document.getElementById("HomePagePopupDontShowAgain").onclick = function () {
            var date = new Date();
            date.setFullYear(date.getFullYear() + 1);;
            document.cookie = "HomePagePopupDontShowAgain=Shown; expires=" + date.toUTCString() + "; SameSite=Strict; path=/;";

            document.getElementById("HomePagePopup").style.display = 'none';
            if (document.getElementById("HomePagePopupModal") !== null) { document.getElementById("HomePagePopupModal").style.display = 'none'; }
        };
    }

    if (document.getElementById("HomePagePopupClose") !== null) {
        document.getElementById("HomePagePopupClose").onclick = function () {
            document.cookie = "HomePagePopupDontShowAgain=Shown; SameSite=Strict; path=/;";

            document.getElementById("HomePagePopup").style.display = 'none';
            if (document.getElementById("HomePagePopupModal") !== null) { document.getElementById("HomePagePopupModal").style.display = 'none'; }
        };
    }
});