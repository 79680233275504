/*
    cookie policy
    Version:1
    Author:Rory Saxby
    Dependencies: jQuery
    
    SUMMARY:

    Controls cookie policy display setting and removing cookies. Currently built to work with RocktimeCore.config setting
    EnableNewStyleCookiePolicyVersionNo = 2. This is the default so no settings need to be set in the initialise example below.

    EXAMPLE:

    *** Initialise - options required ***

    var cookiePolicy = new logicjs.cookiePolicy();

*/

logicjs.cookiePolicy = function(opts){

    if(!opts){
        var opts = {};
    };

    this.opts = {};
    // TODO: Add the ability to inject and remove cookie policy markup
    this.opts.containerTarget = opts.container || "#CookiePolicy";
    this.opts.btnTarget = opts.btnTarget || "#CookiePolicyAccept";
    this.opts.cName = opts.cName || "logicjs-cookie-policy";
    this.opts.cDays = opts.cDays || 1825;
    this.opts.html = opts.html || false;
    this.opts.hiddenClass = opts.hiddenClass || "c-cookie-bar--hidden";

    this.checkCookie();
};

logicjs.cookiePolicy.prototype.setCookie = function(){
    logicjs.setCookie(this.opts.cName, true, this.opts.cDays);
};

logicjs.cookiePolicy.prototype.appendHtml = function(){
    if(this.opts.html){
        // append markup;
    };
};

logicjs.cookiePolicy.prototype.bindCtrl = function(){
    var _ = this;
    this.opts.btn.off('click').on('click', function(){
        _.hideMsg();
    });
};

logicjs.cookiePolicy.prototype.showMsg = function(){
    this.opts.container.removeClass(this.opts.hiddenClass);
};

logicjs.cookiePolicy.prototype.hideMsg = function(){
    this.opts.container.addClass(this.opts.hiddenClass);
};

logicjs.cookiePolicy.prototype.initTargets = function(){
    this.opts.container = $(this.opts.containerTarget);
    this.opts.btn = this.opts.container.find(this.opts.btnTarget);
};

logicjs.cookiePolicy.prototype.checkCookie = function(){
    var value = logicjs.getCookie(this.opts.cName);
    if(value === ""){
        this.setCookie();
        // this.appendHtml();
        this.initTargets();
        this.showMsg();
        this.bindCtrl();
    };
};